import React, { useState } from 'react';
import styles from './ContactPage.module.css'; // Ensure the path is correct



const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);
  
    try {
      const response = await fetch('/sendmail.php', { // Replace 'path/to/your/script.php' with the actual path to your PHP script
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const responseText = await response.text();
        setFeedbackMessage(responseText || 'Thank you for your message!');
        // Reset form fields
        setName('');
        setEmail('');
        setMessage('');
      } else {
        setFeedbackMessage('Oops! Something went wrong, and we couldn\'t send your message.');
      }
    } catch (error) {
      console.error('Error:', error);
      setFeedbackMessage('There was a problem with your submission; please try again.');
    }
  };
  

  
  return (
    <div className={styles.contactContainer}>
      <div className={styles.contactInfo}>
        <h1>Contact Us</h1>
        <p>If you have any questions or feedback, please feel free to reach out to us.</p>
        
      </div>
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" rows="4" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
        <button type="submit" className={styles.button}>Send Message</button>
      </form>
      {feedbackMessage && <div className={styles.feedbackMessage}>{feedbackMessage}</div>}
      <div className={styles.companyDetails}>
          <p>Ulfbrothers OÜ</p>
          {/* <p>TEL: +372........</p> */}
          <p>E-POST: ulf@ulfbrothers.com</p>
          {/* <p>KONTAKT: Aadress (mida meil prg pole)</p> */}
          <p>REG KOOD: 16761468</p>
          {/* <p>KMKR: .....</p> */}
          <p>FB: <a href="https://www.facebook.com/ulfbrothers" target="_blank" rel="noopener noreferrer">facebook.com/ulfbrothers</a></p>
          <p>IG: <a href="https://www.instagram.com/ulfbrothers" target="_blank" rel="noopener noreferrer">instagram.com/ulfbrothers</a></p>
          <p>TIKTOK: <a href="https://www.tiktok.com/@ulfbrothers" target="_blank" rel="noopener noreferrer">tiktok.com/@ulfbrothers</a></p>
        </div>
    </div>
  );
};

export default ContactPage;
