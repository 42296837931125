import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <Link to="/">ULFBROTHERS</Link>
      </div>
      <div className={styles.navMenu}>
        <div className={styles.hamburger} onClick={toggleNav}>
          <i className="fas fa-bars"></i>
        </div>
        <div className={`${styles.navLinksContainer} ${isNavVisible ? styles.active : ''}`}>
          <ul className={styles.navLinks}>
            <li><Link to="/" onClick={() => setIsNavVisible(false)}>Home</Link></li>
            <li><Link to="/products" onClick={() => setIsNavVisible(false)}>Products</Link></li>
            <li><Link to="/contact" onClick={() => setIsNavVisible(false)}>Contact</Link></li>
          </ul>
        </div>
      </div>
      {/* <div className={styles.socialLinks}>
        <a href="https://www.facebook.com/ulfbrothers" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
        <a href="https://www.instagram.com/Ulfbrothers" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
        <a href="https://www.tiktok.com/@ulfbrothers" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a>
      </div> */}
    </nav>
  );
};

export default Navbar;
