import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './HomePage.module.css';
// Consider importing your image if you're using Webpack or a similar bundler
// import BrutalColdImage from './path/to/BrutalCold.png';

const HomePage = () => {
  let navigate = useNavigate();

  const navigateToProducts = () => {
    navigate('/products'); // navigates to the products route
  };

  const [featuredProducts, setFeaturedProducts] = useState([
    {
      name: "Chapo Brutal Cold",
      image: "./images/BrutalCold.png", // Adjust path as needed or use imported images
      description: "Cold Mint",
    },
    {
      name: "Chapo Chrystal Menthol",
      image: "./images/CrystalMenthol.png",
      description: "Icy menthol",
    },
    {
      name: "Mörkö Fatal Freeze",
      image: "./images/Mörkö.png",
      description: "Menthol & Spearmint",
    },
  ]);

  useEffect(() => {
    fetch('/products.json')
      .then((response) => response.json())
      .then((data) => {
    console.log(data.products);
  })
    .catch((error) => {
      console.error('Error fetching JSON:', error);
  });
  }, []);

  return (
    <div className={styles.homeContent}>
      <div className={styles.welcomeSection}>
        <h1>Welcome to ULFBROTHERS</h1>
        <p>Explore our wide range of quality products tailored to meet your needs.</p>
        <button onClick={navigateToProducts} className={styles.btn}>
          Browse Products
        </button>
      </div>
      <div className={styles.products}>
        {featuredProducts.map((product, index) => (
          <div key={index} className={styles.product}>
            <img src={product.image} alt={product.name} />
            <div className={styles.caption}>
              <h2>{product.name}</h2>
              <p>{product.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.socialLinksContainer}>
        <h2>Follow us on social media</h2>
        <div className={styles.socialLinks}>
          <a href="https://www.facebook.com/ulfbrothers" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
          <a href="https://www.instagram.com/Ulfbrothers" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          <a href="https://www.tiktok.com/@ulfbrothers" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a>
        </div>
      </div>
      <div className={styles.aboutUsSection}>
        <div className={styles.aboutUsContent}>
          <div className={styles.aboutUsText}>
            <h2>About ULFBROTHERS</h2>
            <p>
              ULF Brothers is dedicated to offering you the highest quality snus from renowned brands, carefully selected to satisfy the diverse preferences of snus enthusiasts around the world. Whether you are an experienced snus connoisseur or beginning your journey in the world of smokeless tobacco, we have something special just for you.
            </p>
          </div>
          <div className={styles.aboutUsImageContainer}>
            <img src="/images/karbid.png" alt="Our Team" className={styles.aboutUsImage}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
