import React, { useState, useEffect } from 'react';
import styles from './ProductsPage.module.css'; // Assuming you have a CSS module for the styles

const Product = ({ product, onClick }) => {
  return (
    <div className={styles.product} onClick={() => onClick(product)}>
      <img src={product.image} alt={product.name} />
      <div className={styles.caption}>
        <h2>{product.name}</h2>
      </div>
    </div>
  );
};

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    fetch('/products.json') // Update the path if necessary
      .then(response => response.json())
      .then(data => setProducts(data.products))
      .catch(error => console.error("Failed to load products data:", error));
  }, []);

  const handleClick = product => {
    setSelectedProduct(product);
  };

  const handleClose = () => {
    setSelectedProduct(null);
  };

  return (
    <div>
      <div className={styles.header}>
        <h1>Our Products</h1>  
      </div>
      <div className={styles.container}>
        {products.map((product, index) => (
          <Product key={index} product={product} onClick={handleClick} />
        ))}
      </div>
      {selectedProduct && (
        <div className={`${styles.overlay} ${selectedProduct ? styles.active : ''}`} onClick={handleClose}>
        <div className={`${styles.productPopup} ${selectedProduct ? styles.active : ''}`} onClick={e => e.stopPropagation()}>      
          <img src={selectedProduct.image} alt={selectedProduct.name} />
            <div className={styles.productDetails}>
              <h2>{selectedProduct.name}</h2>
              <p><strong>Flavor:</strong> {selectedProduct.flavor}</p>
              <p><strong>Nicotine content:</strong> {selectedProduct.nic_content}</p>
              <p><strong>Pouches per can:</strong> {selectedProduct.pouches}</p>
              <p><strong>Pouch weight:</strong> {selectedProduct.weight}</p>
            </div>
            <span className={styles.closeButton} onClick={handleClose}>&times;</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsPage;
