import React from 'react';
import styles from './Footer.module.css'; // Ensure the path is correct

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.topFooter}>
        <p className={styles.copyRight}>© 2024 Ulfbrothers OÜ. All rights reserved.</p>
        <div className={styles.socialLinks}>
          <a href="https://www.facebook.com/ulfbrothers" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
          <a href="https://www.instagram.com/Ulfbrothers" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          <a href="https://www.tiktok.com/@ulfbrothers" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a>
        </div>
      </div>
      <div className={styles.bottomFooter}>
        {/* <p>TEL: +372........</p> */}
        <p>E-POST: ulf@ulfbrothers.com</p>
        {/* <p>KONTAKT: Aadress (mida meil prg pole)</p> */}
        <p>REG KOOD: 16761468</p>
        {/* <p>KMKR: .....</p> */}
      </div>
    </footer>
  );
};

export default Footer;
